import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorBox as EhrBox } from "@metriport/shared-internal";

export function getAuthUrlPath(): string {
  return process.env.REACT_APP_CANVAS_AUTHORIZE_URL_PATH!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}
export function getRedirectUri(): string {
  return process.env.REACT_APP_CANVAS_REDIRECT_URI!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}

function formatDomain(domain: string): string {
  return domain.endsWith("/") ? domain.slice(0, -1) : domain;
}

export default function LaunchPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const inputParams = new URLSearchParams(location.search);
  const clientId = inputParams.get("client_id");
  const baseDomain = inputParams.get("base_domain");

  useEffect(() => {
    if (!clientId) {
      navigate("/canvas/client-id-error");
      return;
    }
  }, [clientId]);

  useEffect(() => {
    if (!baseDomain) {
      navigate("/canvas/base-domain-error");
      return;
    }
  }, [baseDomain]);

  const redirectParams = new URLSearchParams();
  redirectParams.append("response_type", "code");
  redirectParams.append("scope", "user/Patient.read");
  redirectParams.append("client_id", clientId ?? "temp");
  redirectParams.append("redirect_uri", getRedirectUri());

  const formattedBaseDomain = formatDomain(baseDomain ?? "temp");
  const redirectUrl = `${formattedBaseDomain}${getAuthUrlPath()}?${redirectParams.toString()}`;

  useEffect(() => {
    function callRedirect() {
      window.location.replace(redirectUrl);
    }
    callRedirect();
  }, []);

  return <EhrBox>Launching...</EhrBox>;
}
