import { ChakraProvider, theme } from "@chakra-ui/react";
import {
  AnalyticsProvider,
  BadGateway,
  Forbidden,
  GatewayTimeout,
  NetworkError,
  PatientConsolidatedStateProvider,
  ServiceUnavailable,
  Timeout,
  Unauthorized,
} from "@metriport/shared-internal";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppStateProvider } from "./components/contexts/app";

import * as Sentry from "@sentry/react";
import AthenaLaunchPage from "./components/ehr/athena/LaunchPage";
import AthenaPatientPage from "./components/ehr/athena/PatientPage";
import AthenaTimeoutPage from "./components/ehr/athena/Timeout";
import CanvasClientIdErrorPage from "./components/ehr/canvas/ClientIdErrorPage";
import CanvasDomainErrorPage from "./components/ehr/canvas/DomainErrorPage";
import CanvasLaunchPage from "./components/ehr/canvas/LaunchPage";
import CanvasPatientPage from "./components/ehr/canvas/PatientPage";
import ElationPatientPage from "./components/ehr/elation/PatientPage";
import BadRequestPage from "./components/error/BadRequest";
import PatientNotFoundPage from "./components/error/PatientNotFound";
import HomePage from "./components/home/HomePage";
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-react-router-64-data-api
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App = () => {
  return (
    <main>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <AnalyticsProvider>
            <AppStateProvider>
              <PatientConsolidatedStateProvider>
                <SentryRoutes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/athena/launch" element={<AthenaLaunchPage />} />
                  <Route path="/athena/app" element={<AthenaPatientPage />} />
                  <Route path="/athena/app/timeout" element={<AthenaTimeoutPage />} />
                  <Route path="/canvas/launch" element={<CanvasLaunchPage />} />
                  <Route path="/canvas/app" element={<CanvasPatientPage />} />
                  <Route path="/canvas/client-id-error" element={<CanvasClientIdErrorPage />} />
                  <Route path="/canvas/domain-error" element={<CanvasDomainErrorPage />} />
                  <Route path="/elation/app" element={<ElationPatientPage />} />

                  <Route path="/bad-gateway" element={<BadGateway />} />
                  <Route path="/forbidden" element={<Forbidden />} />
                  <Route path="/gateway-timeout" element={<GatewayTimeout />} />
                  <Route path="/network-error" element={<NetworkError />} />
                  <Route path="/service-unavailable" element={<ServiceUnavailable />} />
                  <Route path="/timeout" element={<Timeout />} />
                  <Route path="/unauthorized" element={<Unauthorized />} />

                  <Route path="/patient-bad-request" element={<BadRequestPage />} />
                  <Route path="/patient-not-found" element={<PatientNotFoundPage />} />
                </SentryRoutes>
              </PatientConsolidatedStateProvider>
            </AppStateProvider>
          </AnalyticsProvider>
        </ChakraProvider>
      </BrowserRouter>
    </main>
  );
};
