import { Box, Text } from "@chakra-ui/react";
import { PatientOverview, SharedStateProvider } from "@metriport/shared-internal";
import { useAppContext } from "../../contexts/app/index";
import { ossBaseUrlAthena } from "../../../api/api";
import { writeResourceToChart } from "../../../api/ehr/athena";

export default function PatientPage() {
  const { state } = useAppContext();

  if (!state.isLoaded) {
    return null;
  }

  return (
    <Box>
      {state.ehrPatientId ? (
        <SharedStateProvider
          authToken={state.authToken}
          baseUrl={ossBaseUrlAthena}
          ehrDetails={{
            ehrPatientId: state.ehrPatientId,
            name: "Athena",
            write: writeResourceToChart,
          }}
        >
          <PatientOverview patientId={state.ehrPatientId} />
        </SharedStateProvider>
      ) : (
        <Text>No Patient</Text>
      )}
    </Box>
  );
}
