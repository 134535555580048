type BaseAppState = {
  authToken?: string;
  ehrPatientId?: string;
};

export type AppState =
  | (BaseAppState & { isLoaded: false })
  | (BaseAppState &
      Omit<BaseAppState, "ehrPatientId" | "authToken"> &
      Required<Pick<BaseAppState, "ehrPatientId" | "authToken">> & { isLoaded: true });

export enum AppStateActionType {
  update,
}

export interface AppStateAction {
  type: AppStateActionType;
  newState: BaseAppState | AppState;
}

export const reducer = (state: AppState, action: AppStateAction) => {
  switch (action.type) {
    case AppStateActionType.update:
      return {
        ...state,
        ...action.newState,
      };

    default:
      return state;
  }
};

export const initialState: AppState = {
  isLoaded: false,
};
