import { getOssProxyOrFail } from "../api";

export async function getMetriportPatientId(patientId: string): Promise<string> {
  const apiOssProxy = getOssProxyOrFail();
  const resp = await apiOssProxy.get(`/patient/${patientId}`);
  if (!resp.data) throw new Error(`Patient ID not returned @ AthenaHealth`);
  return resp.data;
}

export async function writeResourceToChart({
  patientId,
  resource,
  path,
}: {
  patientId: string;
  resource: object;
  path: "medication" | "condition" | "vitals";
}): Promise<void> {
  const apiOssProxy = getOssProxyOrFail();
  await apiOssProxy.post(`/chart/${patientId}/${path}`, resource);
}
