import { Skeleton } from "@chakra-ui/react";
import { handleError, httpStatusCodes } from "@metriport/shared-internal";
import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setAuthTokenHeaders } from "../../../api/api";
import { getMetriportPatientId as athenaGetMetriportPatientId } from "../../../api/ehr/athena";
import { getMetriportPatientId as canvasGetMetriportPatientId } from "../../../api/ehr/canvas";
import { getMetriportPatientId as elationGetMetriportPatientId } from "../../../api/ehr/elation";
import {
  athenaHealthAuthPath,
  authPaths,
  authPathSchema,
  canvasAuthPath,
  elationAuthPath,
} from "../../../domain/ehr";
import useMetriportToast from "../../shared/toast";
import { captureAndDisplayError } from "../../shared/util";
import { AppState, AppStateAction, AppStateActionType, initialState, reducer } from "./reducer";

interface IAppStateContext {
  state: AppState;
  dispatch: Dispatch<AppStateAction>;
}

export const AppStateContext = createContext<IAppStateContext>({
  state: initialState,
  dispatch: () => null,
});

export const AppStateProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const toast = useMetriportToast();

  const path = location.pathname;
  const initializePath = authPaths.includes(path);

  useEffect(() => {
    async function fetchInitialState() {
      if (!initializePath) return;
      try {
        const hash = location.hash;
        const parsedHash = new URLSearchParams(hash.substring(1));
        // Token
        const token = parsedHash.get("access_token");
        navigate(path, { replace: true });
        if (!token) throw new Error("Failed to get token");
        const ehrAuthPath = authPathSchema.parse({ authPath: path });
        setAuthTokenHeaders(token, ehrAuthPath);
        // Patient
        const patient = parsedHash.get("patient");
        if (!patient) throw new Error("Failed to get patient");
        if (ehrAuthPath.authPath === athenaHealthAuthPath) {
          await athenaGetMetriportPatientId(patient);
        } else if (ehrAuthPath.authPath === canvasAuthPath) {
          await canvasGetMetriportPatientId(patient);
        } else if (ehrAuthPath.authPath === elationAuthPath) {
          await elationGetMetriportPatientId(patient);
        }
        dispatch({
          type: AppStateActionType.update,
          newState: {
            ehrPatientId: patient,
            authToken: token,
            isLoaded: true,
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const { handled } = handleError({
          error,
          errorActionsMap: {
            [httpStatusCodes.BAD_REQUEST]: {
              path: "/patient-bad-request",
              toast: "The EHR patient could not be processed",
            },
            [httpStatusCodes.NOT_FOUND]: {
              path: "/patient-not-found",
              toast: "The EHR patient was not found",
            },
          },
          toast,
          navigate,
          includeDefaultActions: true,
        });
        if (!handled) {
          captureAndDisplayError({
            error,
            msg: "Failed to initialize the application, please reach out to support@metriport.com",
            context: "fetchInitialState",
            captureMsg: "Component: AppStateProvider - initializing ehr dash state failed",
            toast,
          });
        }
      }
    }
    fetchInitialState();
  }, []);

  // Prevent that the UI/components are rendered before set the auth token
  if (initializePath && !state.isLoaded) return <Skeleton height="100vh" width="100vw" />;

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>{children}</AppStateContext.Provider>
  );
};

export const useAppContext = () => useContext(AppStateContext);
