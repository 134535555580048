import { capture } from "../../shared/capture";
import { Toast } from "./toast";

export function captureAndDisplayError({
  error,
  msg,
  context,
  captureMsg,
  extras,
  toast,
  hideDisplay = false,
}: {
  error: unknown;
  msg: string;
  context: string;
  captureMsg: string;
  extras?: { [key: string]: unknown };
  toast: Toast;
  hideDisplay?: boolean;
}): void {
  if (!hideDisplay) {
    toast.error({ title: msg });
  }
  capture.error(captureMsg, {
    extra: {
      ...extras,
      msg,
      context,
      error,
    },
  });
}
