import axios, { AxiosInstance } from "axios";
import { isDevEnv } from "../shared/util";
import { EhrAuthPath, athenaHealthAuthPath, canvasAuthPath, elationAuthPath } from "../domain/ehr";

export function getBaseURL(): string {
  return process.env.REACT_APP_API_URL!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}

let apiOssProxy: AxiosInstance | null;
export const ossBaseUrlAthena = `${getBaseURL()}/${isDevEnv() ? "athenahealth" : "athena"}`;
export const ossBaseUrlCanvas = `${getBaseURL()}/canvas`;
export const ossBaseUrlElation = `${getBaseURL()}/elation`;

export function setAuthTokenHeaders(token: string, ehrAuthPath: EhrAuthPath) {
  if (ehrAuthPath.authPath === athenaHealthAuthPath) {
    apiOssProxy = axios.create({ baseURL: ossBaseUrlAthena });
  } else if (ehrAuthPath.authPath === canvasAuthPath) {
    apiOssProxy = axios.create({ baseURL: ossBaseUrlCanvas });
  } else if (ehrAuthPath.authPath === elationAuthPath) {
    apiOssProxy = axios.create({ baseURL: ossBaseUrlElation });
  } else {
    throw new Error("Invalid EHR auth path");
  }
  apiOssProxy.defaults.headers.common["Authorization"] = token;
}

export function getOssProxyOrFail(): AxiosInstance {
  if (!apiOssProxy) throw new Error("Oss proxy not initialized");
  return apiOssProxy;
}
