import z from "zod";

export const athenaHealthAuthPath = "/athena/app";
export const canvasAuthPath = "/canvas/app";
export const elationAuthPath = "/elation/app";

export const authPaths = [athenaHealthAuthPath, canvasAuthPath, elationAuthPath];

export const authPathSchema = z.object({
  authPath: z.enum(authPaths as [string, ...string[]]),
});
export type EhrAuthPath = z.infer<typeof authPathSchema>;
