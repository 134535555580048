import { ErrorBox } from "@metriport/shared-internal";

export default function PatientNotFoundPage() {
  return (
    <ErrorBox>
      The Patient was not found at the EHR. Please try re-opening the app. If the problem persists,
      please reach out to support@metriport.com.
    </ErrorBox>
  );
}
