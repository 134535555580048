import { ErrorBox } from "@metriport/shared-internal";

export default function TimeoutPage() {
  // TODO 2188 Link docs
  return (
    <ErrorBox>
      There was a timeout during the oauth flow. Please try re-opening the app. If the problem
      persists, please reach out to support@metriport.com.
    </ErrorBox>
  );
}
