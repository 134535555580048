import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ErrorBox as EhrBox } from "@metriport/shared-internal";

export function getAuthUrl(): string {
  return process.env.REACT_APP_ATHENA_AUTHORIZE_URL!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}
export function getClientId(): string {
  return process.env.REACT_APP_ATHENA_CLIENT_ID!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}
export function getRedirectUri(): string {
  return process.env.REACT_APP_ATHENA_REDIRECT_URI!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}

export default function LaunchPage() {
  const location = useLocation();
  const inputParams = new URLSearchParams(location.search);
  const iss = inputParams.get("iss");
  const launch = inputParams.get("launch");

  const redirectParams = new URLSearchParams();
  redirectParams.append("response_type", "code");
  redirectParams.append("scope", "launch openid profile fhirUser user/Patient.read");
  redirectParams.append("client_id", getClientId());
  redirectParams.append("redirect_uri", getRedirectUri());
  redirectParams.append("aud", iss ?? "temp");
  redirectParams.append("state", "temp");
  redirectParams.append("launch", launch ?? "temp");

  const redirectUrl = `${getAuthUrl()}?${redirectParams.toString()}`;

  useEffect(() => {
    function callRedirect() {
      window.location.replace(redirectUrl);
    }
    callRedirect();
  }, []);

  return <EhrBox>Launching...</EhrBox>;
}
