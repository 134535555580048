import { Link } from "@chakra-ui/react";
import { ErrorBox } from "@metriport/shared-internal";

export default function BadRequestPage() {
  return (
    <ErrorBox>
      There was a bad request error fetching the EHR patient. Typically this is because of invalid
      demographics - in particular the patient not having at least one complete address. It may also
      mean the patient has opted out of data sharing with Metriport. Please see our documentation{" "}
      <Link
        textDecoration="underline"
        href="https://docs.metriport.com/medical-api/api-reference/patient/create-patient#body"
        target="_blank"
      >
        here
      </Link>{" "}
      to see the required demographics. If the problem persists, please reach out to
      support@metriport.com.
    </ErrorBox>
  );
}
